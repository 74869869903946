exports.components = {
  "component---src-pages-search-index-js": () => import("./../../../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-templates-404-index-jsx": () => import("./../../../src/templates/404/index.jsx" /* webpackChunkName: "component---src-templates-404-index-jsx" */),
  "component---src-templates-advice-index-jsx": () => import("./../../../src/templates/advice/index.jsx" /* webpackChunkName: "component---src-templates-advice-index-jsx" */),
  "component---src-templates-content-index-jsx": () => import("./../../../src/templates/content/index.jsx" /* webpackChunkName: "component---src-templates-content-index-jsx" */),
  "component---src-templates-event-category-index-jsx": () => import("./../../../src/templates/event-category/index.jsx" /* webpackChunkName: "component---src-templates-event-category-index-jsx" */),
  "component---src-templates-events-all-index-jsx": () => import("./../../../src/templates/events-all/index.jsx" /* webpackChunkName: "component---src-templates-events-all-index-jsx" */),
  "component---src-templates-events-landing-index-jsx": () => import("./../../../src/templates/events-landing/index.jsx" /* webpackChunkName: "component---src-templates-events-landing-index-jsx" */),
  "component---src-templates-legal-homepage-index-jsx": () => import("./../../../src/templates/legal-homepage/index.jsx" /* webpackChunkName: "component---src-templates-legal-homepage-index-jsx" */),
  "component---src-templates-legal-index-jsx": () => import("./../../../src/templates/legal/index.jsx" /* webpackChunkName: "component---src-templates-legal-index-jsx" */),
  "component---src-templates-person-index-jsx": () => import("./../../../src/templates/person/index.jsx" /* webpackChunkName: "component---src-templates-person-index-jsx" */),
  "component---src-templates-personalised-advice-index-jsx": () => import("./../../../src/templates/personalised-advice/index.jsx" /* webpackChunkName: "component---src-templates-personalised-advice-index-jsx" */),
  "component---src-templates-policy-index-jsx": () => import("./../../../src/templates/policy/index.jsx" /* webpackChunkName: "component---src-templates-policy-index-jsx" */),
  "component---src-templates-policy-library-index-jsx": () => import("./../../../src/templates/policy-library/index.jsx" /* webpackChunkName: "component---src-templates-policy-library-index-jsx" */),
  "component---src-templates-press-release-index-jsx": () => import("./../../../src/templates/press-release/index.jsx" /* webpackChunkName: "component---src-templates-press-release-index-jsx" */),
  "component---src-templates-press-release-listings-index-jsx": () => import("./../../../src/templates/press-release-listings/index.jsx" /* webpackChunkName: "component---src-templates-press-release-listings-index-jsx" */),
  "component---src-templates-service-index-jsx": () => import("./../../../src/templates/service/index.jsx" /* webpackChunkName: "component---src-templates-service-index-jsx" */),
  "component---src-templates-shop-index-jsx": () => import("./../../../src/templates/shop/index.jsx" /* webpackChunkName: "component---src-templates-shop-index-jsx" */),
  "component---src-templates-standard-event-index-jsx": () => import("./../../../src/templates/standard-event/index.jsx" /* webpackChunkName: "component---src-templates-standard-event-index-jsx" */)
}

